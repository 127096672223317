import {Route, Routes} from 'react-router-dom';
import { lazy } from 'react';

import AccessAuthenticated from './access/AccessAuthenticated';
import AccessMaster from './access/AccessMaster';
import React from 'react';
import InitClubManagers from './init/InitClubManagers';
import InitSuperAdmin from './init/InitSuperAdmin';
import LayoutMain from './layouts/LayoutMain/LayoutMain';
import LayoutTemparoryMain from './layouts/LayoutTemparory/LayoutTemparoryMain';
import RoutesRestricted
  from './routes/ClubManager/RoutesRestricted';
import RoutesSchedule
  from './routes/ClubManager/RoutesSchedule';
import RoutesBookings
  from './routes/ClubManager/RoutesBookings';
import RoutesCommunications
  from './routes/ClubManager/RoutesCommunications';
import RoutesFinancials
  from './routes/ClubManager/RoutesFinancials';
import RoutesWallets
  from './routes/ClubManager/RoutesWallets';
import RoutesEvents
  from './routes/ClubManager/RoutesEvents';
import RoutesCustomers
  from './routes/ClubManager/RoutesCustomers';
import RoutesReports
  from './routes/ClubManager/RoutesReports';
import RoutesSettings
  from './routes/ClubManager/RoutesSettings';
import RoutesSettingsCourts
  from './routes/ClubManager/RoutesSettingsCourts';
import RoutesSettingsClubs
  from './routes/ClubManager/RoutesSettingsClubs';
import RoutesSettingsExtras
  from './routes/ClubManager/RoutesSettingsExtras';
import RoutesSettingsHolidays
  from './routes/ClubManager/RoutesSettingsHolidays';
import RoutesSettingsManageAdmins
  from './routes/ClubManager/RoutesSettingsManageAdmins';
import RoutesSettingsPricing
  from './routes/ClubManager/RoutesSettingsPricing';
import RoutesSettingsCompany
  from './routes/ClubManager/RoutesSettingsCompany';
import RoutesPolicies
  from './routes/ClubManager/RoutesPolicies';
import RoutesProfile
  from './routes/ClubManager/RoutesProfile';
import RoutesPackages from './routes/ClubManager/RoutesPackages';
import useIsRole from './hooks/access/useIsRole';
import RoutesSuperAdmin from './routes/SuperAdmin/RoutesSuperAdmin';
import RoutesSettingsIntegration from './routes/ClubManager/RoutesSettingsIntegration';
import RoutesMeshlink from './routes/ClubManager/RoutesMeshlink';
const PageLoginForgotPassword = lazy(() => import('./pages/Login/PageLoginForgotPassword'));
const PageLogin = lazy(() => import('./pages/Login/PageLogin'));
const PageLoginResetPassword = lazy(() => import('./pages/Login/PageLoginResetPassword'));
const  PageBookingFullData = lazy(()=> import( './pages/Bookings/PageBookingFullData'));
const  PageRecurringBookingFullData = lazy(()=> import( './pages/Bookings/PageRecurringFullData'));
const  PageCustomersFullData = lazy(()=> import( './pages/Customers/PageCustomersFullData'));



function App() {

  const isSuperAdmin = useIsRole('super_admin');

  return (
      <Routes>
        <Route element={<AccessMaster/>}>

          {/*Public Routes*/}
          <Route path="/login"
                 element={<PageLogin/>}
          />
          <Route path="/forgot-password"
                 element={<PageLoginForgotPassword/>}
          />
          <Route path="/reset-password/:token"
                 element={<PageLoginResetPassword/>}
          />

          {/*Authenticated Routes*/}
          <Route element={<AccessAuthenticated/>}>
            {
                isSuperAdmin &&
                <Route element={<InitSuperAdmin/>}>
                <Route element={<LayoutMain role={'super_admin'}/>}>
                  {RoutesSuperAdmin}
                </Route>
                </Route>
            }
            {
                !isSuperAdmin &&
                <Route element={<InitClubManagers/>}>
                  <Route element={<LayoutTemparoryMain role={'club_manager'}/>}>
                    <Route element={<PageBookingFullData />} path='/bookings/:name' />
                    <Route element={<PageRecurringBookingFullData />} path='/recurring/bookings' />
                    <Route element={<PageCustomersFullData />} path='/customers/list' />

                  </Route>
                  <Route element={<LayoutMain role={'club_manager'}/>}>
                    {RoutesRestricted}
                    {RoutesProfile}
                    {RoutesSchedule}
                    {RoutesBookings}
                    {RoutesCommunications}
                    {RoutesFinancials}
                    {RoutesWallets}
                    {RoutesEvents}
                    {RoutesCustomers}
                    {RoutesReports}
                    {RoutesSettings}
                    {RoutesSettingsCourts}
                    {RoutesSettingsClubs}
                    {RoutesSettingsExtras}
                    {RoutesSettingsHolidays}
                    {RoutesSettingsManageAdmins}
                    {RoutesSettingsPricing}
                    {RoutesSettingsCompany}
                    {RoutesPackages}
                    {RoutesPolicies}
                    {RoutesSettingsIntegration}
                    {RoutesMeshlink}
                  </Route>
                </Route>
            }

          </Route>

        </Route>
      </Routes>
  );

}

export default App;

// import {Route, Routes} from 'react-router-dom';
// import AccessAuthenticated from './access/AccessAuthenticated';
// import AccessMaster from './access/AccessMaster';
// import React, {lazy, Suspense} from 'react';
// import InitClubManagers from './init/InitClubManagers';
// import InitSuperAdmin from './init/InitSuperAdmin';
// import LayoutMain from './layouts/LayoutMain/LayoutMain';
// import useIsRole from './hooks/access/useIsRole';
// import LayoutTemparoryMain from './layouts/LayoutTemparory/LayoutTemparoryMain';
// // import PageRecurringBookingFullData from './pages/Bookings/PageRecurringFullData';
// import PageCustomersFullData from './pages/Customers/PageCustomersFullData';
// import RoutesSchedule from './routes/ClubManager/RoutesSchedule'

// const PageLoginForgotPassword =lazy (()=>import('./pages/Login/PageLoginForgotPassword')) ;
// const PageLogin = lazy(() => import('./pages/Login/PageLogin'));
// const PageLoginResetPassword = lazy(() => import('./pages/Login/PageLoginResetPassword'));

// const RoutesRestricted = lazy(() => import('./routes/ClubManager/RoutesRestricted'));
// // const RoutesSchedule = lazy(() => import('./routes/ClubManager/RoutesSchedule'));
// // const RoutesBookings = lazy(() => import('./routes/ClubManager/RoutesBookings'));
// const RoutesCommunications = lazy(() => import('./routes/ClubManager/RoutesCommunications'));
// const RoutesFinancials = lazy(() => import('./routes/ClubManager/RoutesFinancials'));
// const RoutesWallets = lazy(() => import('./routes/ClubManager/RoutesWallets'));
// const RoutesEvents = lazy(() => import('./routes/ClubManager/RoutesEvents'));
// const RoutesCustomers = lazy(() => import('./routes/ClubManager/RoutesCustomers'));
// const RoutesReports = lazy(() => import('./routes/ClubManager/RoutesReports'));
// const RoutesSettings = lazy(() => import('./routes/ClubManager/RoutesSettings'));
// const RoutesSettingsCourts = lazy(() => import('./routes/ClubManager/RoutesSettingsCourts'));
// const RoutesSettingsClubs = lazy(() => import('./routes/ClubManager/RoutesSettingsClubs'));
// const RoutesSettingsExtras = lazy(() => import('./routes/ClubManager/RoutesSettingsExtras'));
// const RoutesSettingsHolidays = lazy(() => import('./routes/ClubManager/RoutesSettingsHolidays'));
// const RoutesSettingsManageAdmins = lazy(() => import('./routes/ClubManager/RoutesSettingsManageAdmins'));
// const RoutesSettingsPricing = lazy(() => import('./routes/ClubManager/RoutesSettingsPricing'));
// const RoutesSettingsCompany = lazy(() => import('./routes/ClubManager/RoutesSettingsCompany'));
// const RoutesPolicies = lazy(() => import('./routes/ClubManager/RoutesPolicies'));
// const RoutesProfile = lazy(() => import('./routes/ClubManager/RoutesProfile'));
// const RoutesPackages = lazy(() => import('./routes/ClubManager/RoutesPackages'));
// const RoutesSuperAdmin = lazy(() => import('./routes/SuperAdmin/RoutesSuperAdmin'));
// const RoutesSettingsIntegration = lazy(() => import('./routes/ClubManager/RoutesSettingsIntegration'));
// const RoutesMeshlink = lazy(() => import('./routes/ClubManager/RoutesMeshlink'));
// const PageBookingFullData = lazy(() => import('./pages/Bookings/PageBookingFullData'));
// const PageRecurringBookingFullData = lazy(() => import('./pages/Bookings/PageRecurringFullData'));

// function App() {
//   const isSuperAdmin = useIsRole('super_admin');

//   return (
//     <Routes>
//     <Route element={<AccessMaster/>}>
  
//       {/* Public Routes */}
//       <Route path="/login" element={<PageLogin/>} />
//       <Route path="/forgot-password" element={<PageLoginForgotPassword/>} />
//       <Route path="/reset-password/:token" element={<PageLoginResetPassword/>} />
  
//       {/* Authenticated Routes */}
//       <Route element={<AccessAuthenticated/>}>
//         {/* {isSuperAdmin && (
//           <Suspense fallback={<div>Loading...</div>}>
//             <Route element={<LayoutMain role={'super_admin'}/>}>
//               <RoutesSuperAdmin />
//             </Route>
//           // </Suspense>
//         )} */}
//         {!isSuperAdmin && (
//             <Route element={<InitClubManagers/>}>
//              <Route element={<LayoutTemparoryMain role={'club_manager'}/>}>
//                   <Route element={<PageBookingFullData />} path='/bookings/:name' />
//                   <Route element={<PageRecurringBookingFullData />} path='/recurring/bookings' />
//                   <Route element={<PageCustomersFullData />} path='/customers/list' />
//               </Route>
//               <Route element={<LayoutMain role={'club_manager'}/>}>
                
//                   {RoutesSchedule}
//                   {/* {RoutesRestricted} */}
//                   {/* {RoutesProfile}
//                   {RoutesSchedule}
//                   {RoutesCommunications}
//                   {RoutesFinancials}
//                   {RoutesWallets}
//                   {RoutesEvents}
//                   {RoutesCustomers}
//                   {RoutesReports}
//                   {RoutesSettings}
//                   {RoutesSettingsCourts}
//                   {RoutesSettingsClubs}
//                   {RoutesSettingsExtras}
//                   {RoutesSettingsHolidays}
//                   {RoutesSettingsManageAdmins}
//                   {RoutesSettingsPricing}
//                   {RoutesSettingsCompany}
//                   {RoutesPackages}
//                   {RoutesPolicies}
//                   {RoutesSettingsIntegration}
//                   {RoutesMeshlink} */}
//               </Route>
//             </Route>
//         )}
        
//       </Route>
  
//     </Route>
//   </Routes>
//   );

// }

// export default App;
