import {useState} from 'react';
import {Box, ListItemText, Menu, MenuItem} from '@mui/material';
import {ArrowDropDown, ArrowDropUp} from '@mui/icons-material';
import {Link} from 'react-router-dom';

export function LayoutMainNavbarNavSubMenu({items}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (clickFunc) => {

    if(clickFunc){
      clickFunc();
    }

    setAnchorEl(null);
  };

  return (<>
    <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'text.secondary',
        }}
        onClick={handleClick}
    >
      {!open && <ArrowDropDown/>}
      {open && <ArrowDropUp/>}
    </Box>

    <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={()=>handleClose()}
        sx={{
          mt: 1,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}

    >
      {
        items.map((item, i) => {
          if (item.canAccess) {
            return <MenuItem
                key={i}
                component={Link}
                to={item.route}
                onClick={()=>handleClose(item?.onClick)}
                disabled={item.disabled}
                sx={{
                  '.text': {
                    fontSize: '14px',
                  },
                }}
            >
              <ListItemText
                  disableTypography
                  className={'text'}
              >{item.name}</ListItemText>
            </MenuItem>;
          }
        })
      }

    </Menu>
  </>);
}