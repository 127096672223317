import {Navigate, Outlet} from 'react-router-dom';
import useUser from '../hooks/access/useUser';
import useIsAuthenticated from '../hooks/access/useIsAuthenticated';

function AccessAuthenticated() {

  const user = useUser();
  const isAuthenticated = useIsAuthenticated();

  return (isAuthenticated && user) ?
      <Outlet/> :
      <Navigate to="/login"/>;
}

export default AccessAuthenticated;
