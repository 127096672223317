export function getIsLocal(){
  return process.env.REACT_APP_ENV === 'local'
}


export function getIsRelease(){
  return process.env.REACT_APP_ENV === 'pre_production'
}

export function getIsStaging(){
  return process.env.REACT_APP_ENV === 'staging'
}

export function getIsProduction(){
  return process.env.REACT_APP_ENV === 'production'
}