import {Box, Button, Stack, Typography, useTheme} from '@mui/material';
import LayoutMainHeaderTopClubSelect from './LayoutMainHeaderTopClubSelect';
import SiteUserProfile from '../../components/_Global/Site/SiteUserProfile';
import {LAYOUT_MAIN__HEADER__TOP__HEIGHT} from '../../constants/ui';
import ContainerFullWidth
  from '../../components/_Global/Containers/ContainerFullWidth';
import LayoutMainHeaderTopLogo from './LayoutMainHeaderTopLogo';
import { useTranslate } from '@tolgee/react';
import { useEffect } from 'react';
import { useState } from 'react';
import IconAnnouncment from '../../assets/icons/IconAnnouncment';
import IconLeft from '../../assets/icons/IconLeft';

function LayoutMainHeaderTop({role, disableDropdown}) {

  const theme = useTheme();
  const {t} = useTranslate();
  const [isZendeskOpen, setIsZendeskOpen] = useState(false);
  const openZendeskWidget = () => {
      if (typeof window.openCloseZendeskWidget === 'function') {
        window.openCloseZendeskWidget();
        setIsZendeskOpen(!isZendeskOpen);
      } else {
        console.error('Zendesk open function is not defined');
      }
  };
  
  return (

      <Box
          sx={{
            borderBottom: `1px solid ${theme.palette.divider}`,
            backgroundColor: theme.palette.neutral.primary,
          }}
      >
        <ContainerFullWidth>
          <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: LAYOUT_MAIN__HEADER__TOP__HEIGHT,
              }}
          >
            {
                role === 'super_admin' &&
                <LayoutMainHeaderTopLogo/>
            }
            {
                role === 'club_manager' &&
                <LayoutMainHeaderTopClubSelect disableDropdown={disableDropdown}/>
            }
           

            <Stack
                sx={{marginLeft: 'auto'}}
                direction={'row'}
                alignItems={'center'}
            >
           {/* {process.env.REACT_APP_ENV === 'production' &&
            <Button color={isZendeskOpen ? 'primary' :'inherit'} onClick={openZendeskWidget}>
              <Typography sx={{fontSize: '14px',
                        fontWeight: 500,padding:'.4rem', ml: 0.2}}>
                    {t('zendesk.button')}
                        </Typography>
              </Button>
 } */}
              {/*<LayoutMainHeaderTopMetrics/>*/}
              <Box mr={5}>
                {/*<LayoutMainHeaderTopMetrics/>*/}
                <Button  color='inherit' className='beamerTrigger'>
                {/* <IconAnnouncment fill={'rgba(0, 0, 0, 0.56)'} /> */}
                  <Typography sx={{fontSize: '14px',
                        fontWeight: 500,padding:'.4rem', ml: 0.5}}>
                    {t('global.beamer.buttonTitle')}
                  </Typography>
                </Button>
              </Box>  
              <SiteUserProfile role={role}/>
            </Stack>
          </Box>
        </ContainerFullWidth>
      </Box>

  );
}

export default LayoutMainHeaderTop;
