import { lazy } from 'react';
import {Route} from 'react-router-dom';
const PageProfile = lazy(() => import('../../pages/Profile/PageProfile'));
const PageProfileEdit = lazy(() => import('../../pages/Profile/PageProfileEdit'));


const RoutesSuperAdminProfile = (<>
  <Route
      path={'/settings/profile'}
      element={<PageProfile role={'super_admin'}/>}

  />
  <Route
      path={'/settings/profile/edit'}
      element={<PageProfileEdit role={'super_admin'}/>}
  />
</>)

export default RoutesSuperAdminProfile;