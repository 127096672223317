/* eslint-disable camelcase */
import {Record} from 'immutable';
import {
  CHANGE_LOCALE,
  CLUBS_SWITCHER_GET_FAILURE,
  CLUBS_SWITCHER_GET_LOADING,
  CLUBS_SWITCHER_GET_SUCCESS,
  GET_COUNTRY_FAILURE,
  GET_COUNTRY_LOADING,
  GET_COUNTRY_SUCCESS,
  GET_SPORTS_FAILURE,
  GET_SPORTS_LOADING,
  GET_SPORTS_SUCCESS,
  POLICIES_FAILURE,
  POLICIES_LOADING,
  POLICIES_SUCCESS,
  RESET_REDUX_STATE,
  SET_COMPANY_DEFAULTS,
  SET_SELECTED_CLUB,
  USER_LOGIN,
  USER_LOGOUT,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_LOADING,
  GET_COMPANY_FAILURE,
  CLUBS_INTEGRATION_LIST_GET_LOADING,
  CLUBS_INTEGRATION_LIST_GET_SUCCESS,
  CLUBS_INTEGRATION_LIST_GET_FAILURE
} from '../constants';

const userFromLocalStorage = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : null;

const localeFromLocalStorage = !!localStorage.getItem('locale') ?
    JSON.parse(localStorage.getItem('locale')) :
    'en';

const defaultValues = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  errMsg: null,
  countries: null,
  sports: null,
  clubs: null,
  selectedClub: null,
  companyDefaults: null,
  locale: localeFromLocalStorage,
  user: userFromLocalStorage,
  isAuthenticated: !!userFromLocalStorage,
  policies: null,
  company: null,
  clubsIntegrations: null
};

const State = new Record(defaultValues);
const initialState = new State();

function appReducer(state = initialState, action) {
  switch (action.type) {

    case USER_LOGIN:
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true,
      };

    case USER_LOGOUT:
      return {
        ...state,
        user: null,
        isAuthenticated: false,
      };

    case CHANGE_LOCALE :
      return {
        ...state,
        locale: action.payload,
      };

    case SET_SELECTED_CLUB:
      return {
        ...state,
        selectedClub: action.payload,
      };

    case SET_COMPANY_DEFAULTS:
      return {
        ...state,
        companyDefaults: action.payload,
      };

    case GET_COUNTRY_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
        isSuccess: false,
        errMsg: null,
      };

    case GET_COUNTRY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: false,
        errMsg: null,
        countries: action.payload,
      };

    case GET_COUNTRY_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: false,
        errMsg: action.payload,
        countries: null,
      };

    case GET_SPORTS_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
        isSuccess: false,
        errMsg: null,
      };

    case GET_SPORTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: false,
        errMsg: null,
        sports: action.payload,
      };

    case GET_SPORTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: false,
        errMsg: action.payload,
        sports: null,
      };

    case CLUBS_SWITCHER_GET_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
        isSuccess: false,
        errMsg: null,
      };

    case CLUBS_SWITCHER_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: false,
        errMsg: null,
        clubs: action.payload,
      };

    case CLUBS_SWITCHER_GET_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: false,
        errMsg: action.payload,
        sports: null,
      };

  case POLICIES_LOADING:
    return {
      ...state,
      isLoading: true,
      isError: false,
      isSuccess: false,
      errMsg: null,
    };

  case POLICIES_SUCCESS:
    return {
      ...state,
      isLoading: false,
      isError: false,
      isSuccess: false,
      errMsg: null,
      policies: action.payload,
    };

  case POLICIES_FAILURE:
    return {
      ...state,
      isLoading: false,
      isError: false,
      isSuccess: false,
      errMsg: action.payload,
      sports: null,
    };
    case GET_COMPANY_LOADING:
    return {
      ...state,
      isLoading: true,
      isError: false,
      isSuccess: false,
      errMsg: null,
    };

  case GET_COMPANY_SUCCESS:
    return {
      ...state,
      isLoading: false,
      isError: false,
      isSuccess: false,
      errMsg: null,
      company: action.payload,
    };

  case GET_COMPANY_FAILURE:
    return {
      ...state,
      isLoading: false,
      isError: false,
      isSuccess: false,
      errMsg: action.payload,
      sports: null,
    };

    case CLUBS_INTEGRATION_LIST_GET_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
        isSuccess: false,
        errMsg: null,
      };
  
    case CLUBS_INTEGRATION_LIST_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: false,
        errMsg: null,
        clubsIntegrations: action.payload,
      };
  
    case CLUBS_INTEGRATION_LIST_GET_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: false,
        errMsg: action.payload,
        sports: null,
      };

    case RESET_REDUX_STATE:
      return initialState;

    default:
      return state;
  }
}

export default appReducer;
