import { Box } from '@mui/material';
import LayoutMainHeaderBottom from './LayoutMainHeaderBottom';
import LayoutMainHeaderTop from './LayoutMainHeaderTop';
import { useSelector } from 'react-redux';
import { useTranslate } from '@tolgee/react';
import { useNavigate,Link } from 'react-router-dom';
import useHasCapability from '../../hooks/access/useHasCapability';
import { CAPABILITY_UPDATE } from '../../constants/capabilities';
import { MODULE_COMPANY } from '../../constants/modules';

function LayoutMainHeader({role, showBottomLayout = true}) {
  

  return (
      <Box
          component={'header'}
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
      >
        <LayoutMainHeaderTop role={role} disableDropdown={!showBottomLayout}/>
       {showBottomLayout &&
        <LayoutMainHeaderBottom role={role}/>}
       
         
      </Box>
  );
}

export default LayoutMainHeader;
