import {Route} from 'react-router-dom';
import AccessProtected from '../../access/AccessProtected';
import {
  CAPABILITY_READ,
  CAPABILITY_READ_ALL,
} from '../../constants/capabilities';
import {MODULE_REPORTS} from '../../constants/modules';
import { lazy } from 'react';
const  PageReports = lazy(()=> import( '../../pages/Reports/PageReports'));
const  PageReportsDetail = lazy(()=> import( '../../pages/Reports/PageReportsDetail'));


const RoutesReports = (
    <>
        {/*REPORTS MODULE*/}
        <Route path={`/reports`}>

            {/*READ_ALL REPORTS*/}
            <Route element={<AccessProtected
                capabilities={CAPABILITY_READ_ALL}
                module={MODULE_REPORTS}
            />}>
                <Route index element={<PageReports/>}/>
            </Route>

            {/*READ REPORTS*/}
            <Route element={<AccessProtected
                capabilities={CAPABILITY_READ}
                module={MODULE_REPORTS}
            />}>
                <Route path="revenue" element={<PageReportsDetail report={'revenue'}/>}/>
                <Route path="reservations-per-booking-date" element={<PageReportsDetail report={'reservations-per-booking-date'}/>}/>
                <Route path="reservations-per-play-date" element={<PageReportsDetail report={'reservations-per-play-date'}/>}/>
                <Route path="cancellations-per-booking-date" element={<PageReportsDetail report={'cancellations-per-booking-date'}/>}/>
                <Route path="cancellations-per-play-date" element={<PageReportsDetail report={'cancellations-per-play-date'}/>}/>
                <Route path="occupancy-per-day" element={<PageReportsDetail report={'occupancy-per-day'}/>}/>
                <Route path="occupancy-percent-per-day" element={<PageReportsDetail report={'occupancy-percent-per-day'}/>}/>
            </Route>

        </Route>
    </>
);




export default RoutesReports;
