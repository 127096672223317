import {createContext, useEffect, useState} from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/nl';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';
import 'dayjs/locale/es';
import 'moment/locale/nl';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/es';
import useDefaults from '../hooks/defaults/useDefaults';
import moment from 'moment-timezone';

export const LocaleContext = createContext();

export default function ProviderLocale({children}) {

  const {defaultLocale} = useDefaults();

  const localStorageLocale = JSON.parse(localStorage.getItem('locale'));
  const [locale, setLocale] = useState(localStorageLocale || defaultLocale);

  useEffect(() => {
    dayjs.locale(locale);
    moment.locale(locale);
  }, [locale]);

  return (
      <LocaleContext.Provider value={{locale, setLocale}}>
        {children}
      </LocaleContext.Provider>
  );

}
