import {Route} from 'react-router-dom';
import AccessProtected from '../../access/AccessProtected';
import {
  CAPABILITY_CREATE,
  CAPABILITY_READ,
  CAPABILITY_READ_ALL,
  CAPABILITY_UPDATE,
} from '../../constants/capabilities';
import { MODULE_INTEGRATIONS} from '../../constants/modules';
import { lazy } from 'react';
const  PageIntegrations = lazy(()=> import( '../../pages/Integerations/PageIntegrations'));

const RoutesSettingsIntegration = (
    <>
      <Route path={`/settings/integration`}>
        {/*READ_ALL EXTRAS*/}
        <Route element={<AccessProtected
            capabilities={CAPABILITY_READ_ALL}
            module={MODULE_INTEGRATIONS} 
            isIntegrations={true}
        />}>
          <Route index element={<PageIntegrations/>}/>
        </Route>
      

      </Route>
    </>
);

export default RoutesSettingsIntegration;
