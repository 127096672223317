import {Route} from 'react-router-dom';
import AccessProtected from '../../access/AccessProtected';
import {
  CAPABILITY_CREATE,
  CAPABILITY_READ,
  CAPABILITY_READ_ALL,
  CAPABILITY_UPDATE,
} from '../../constants/capabilities';
import {MODULE_CLUBS} from '../../constants/modules';
import { lazy } from 'react';
const  PageClubsList = lazy(()=> import( '../../pages/Clubs/PageClubsList'));
const  PageClubsDetail = lazy(()=> import( '../../pages/Clubs/PageClubsDetail'));
const  PageClubsAddEdit = lazy(()=> import( '../../pages/Clubs/PageClubsAddEdit'));

const RoutesSettingsClubs = (
    <>
      <Route path={`/settings/clubs`}>
        {/*READ_ALL CLUBS*/}
        <Route element={<AccessProtected
            capabilities={CAPABILITY_READ_ALL}
            module={MODULE_CLUBS}
        />}>
          <Route index element={<PageClubsList
              role={'club_manager'}
              type={'single_company_list'}
          />}/>
        </Route>
        {/*READ CLUBS*/}
        <Route element={<AccessProtected
            capabilities={CAPABILITY_READ}
            module={MODULE_CLUBS}
        />}>
          <Route path="detail/:clubId" element={<PageClubsDetail/>}/>
        </Route>
        {/*CREATE CLUBS*/}
        <Route element={<AccessProtected
            capabilities={CAPABILITY_CREATE}
            module={MODULE_CLUBS}
        />}>
          <Route
              path={`add`}
              element={<PageClubsAddEdit
                  mode={'add'}
                  role={'club_manager'}
              />}/>
        </Route>
        {/*EDIT CLUBS*/}
        <Route element={<AccessProtected
            capabilities={CAPABILITY_UPDATE}
            module={MODULE_CLUBS}
        />}>
          <Route path={`edit/:clubId`}
                 element={<PageClubsAddEdit
                     mode={'edit'}
                     role={'club_manager'}
                 />}/>
        </Route>
      </Route>
    </>
);

export default RoutesSettingsClubs;
