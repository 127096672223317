import ReactGA from 'react-ga4';

export function fireGAEvent(
    action, category = '', label = '', otherParams = {},value = '') {

  if (!action) {
    throw new Error('action is a required param');
  }

  ReactGA.event(action, {
    category,
    label,
    value,
    ...otherParams,
  });

  console.log(`ReactGA ${action} event fired`,otherParams);

}

