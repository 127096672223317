import {Route} from 'react-router-dom';
import { lazy } from 'react';
const PageProfile = lazy(()=> import( '../../pages/Profile/PageProfile'));
const PageProfileEdit = lazy(()=> import( '../../pages/Profile/PageProfileEdit'));

const RoutesProfile = (
    <>
        {/*REPORTS MODULE*/}
        <Route path={`/profile`}>
          <Route index element={<PageProfile role={'club_manager'}/>}/>
          <Route path='edit' element={<PageProfileEdit role={'club_manager'}/>}/>

        </Route>
    </>
);

export default RoutesProfile;
