import {createContext, useContext} from 'react';

export const AppContext = createContext();

export function ProviderApp({children}) {

  return (
      <AppContext.Provider value={{}}>
        {children}
      </AppContext.Provider>
  );
}

export function useAppContext() {
  const {} = useContext(AppContext);
  return {};
}