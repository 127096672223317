import {Route} from 'react-router-dom';
import AccessProtected from '../../access/AccessProtected';
import {
  CAPABILITY_CREATE,
  CAPABILITY_READ_ALL,
} from '../../constants/capabilities';
import {MODULE_WALLET} from '../../constants/modules';
import { lazy } from 'react';
const  PageWallets = lazy(()=> import( '../../pages/Wallets/PageWallets'));
const  PageWalletsTransactionDetail = lazy(()=> import( '../../pages/Wallets/PageWalletsTransactionDetail'));
const  PageWalletsOffersAddEdit = lazy(()=> import( '../../pages/Wallets/PageWalletsOffersAddEdit'));
const  PageWalletsOffersDetail = lazy(()=> import( '../../pages/Wallets/PageWalletsOffersDetail'));
const  PageWalletsDeductTransactionDetail = lazy(()=> import( '../../pages/Wallets/PageWalletsDeductTransactionDetail'));


const RoutesWallets = (
    <>
        {/*Wallets Module*/}
        <Route element={<AccessProtected
            capabilities={CAPABILITY_READ_ALL}
            module={MODULE_WALLET}
        />}>
            <Route
                path={`/wallets`}
                element={<PageWallets tab={0}/>}
            />
            <Route
                path={`/wallets/transactions`}
                element={<PageWallets tab={0}/>}
            />
            <Route
                path={`/wallets/wallet/*`}
                element={<PageWallets tab={1}/>}
            />
            <Route
                path={`/wallets/offers`}
                element={<PageWallets tab={2}/>}
            />
            <Route
                path={`/wallets/transactions/detail`}
                element={<PageWalletsTransactionDetail/>}
            />
            <Route
                path={`/wallets/deduct/transactions/detail`}
                element={<PageWalletsDeductTransactionDetail/>}
            />
            
            <Route
                path={`/wallets/offers/detail`}
                element={<PageWalletsOffersDetail/>}
            />


        </Route>
        <Route element={<AccessProtected
            capabilities={CAPABILITY_CREATE}
            module={MODULE_WALLET}
        />}>
            <Route 
                path={`/wallets/offers/edit`}
                element={<PageWalletsOffersAddEdit mode={'edit'}/>}
                />
                <Route
                path={`/wallets/offers/add`}
                element={<PageWalletsOffersAddEdit mode={'add'}/>}
                /> 

        </Route>
    </>
)

export default RoutesWallets;
