import {Box, Menu, MenuItem, Typography, useTheme} from '@mui/material';
import {useTranslate} from '@tolgee/react';
import {useState} from 'react';
import {useSnackbar} from 'notistack';
import {useDispatch} from 'react-redux';
import ProfileImageViewer from '../Profile/ProfileImageViewer';
import useUser from '../../../hooks/access/useUser';
import {useNavigate} from 'react-router-dom';
import useLogout from '../../../hooks/access/useLogout';

function SiteUserProfile({role}) {

  const dispatch = useDispatch();
  const theme = useTheme();
  const {enqueueSnackbar} = useSnackbar();
  const {t} = useTranslate();
  const navigateTo = useNavigate();

  const {logout} = useLogout();

  const user = useUser();
  const userObject = user?.userObj;

  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  function handleCloseMenu() {
    setAnchorEl(null);
  }

  function handleUserLogout() {
    logout();
    enqueueSnackbar('You have successfully logged out', {variant: 'success'});
  }

  const handleNavigate = () => {
    if (role === 'club_manager') {
      navigateTo('/profile');
    }
    if (role === 'super_admin') {
      navigateTo('/settings/profile');
    }
  };
  return (<Box
      sx={{
        display: 'flex',
        marginLeft: 'auto',
        alignItems: 'center',
      }}
  >
    <Box
        sx={{
          cursor: 'pointer',
        }}
        onClick={handleOpenMenu}
    >
      <ProfileImageViewer
          size={'xs'}
          imgPath={userObject?.imageUrl}
          placeholderStyle={'user-icon'}
      />

    </Box>
    <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleCloseMenu}
        PaperProps={{
          sx: {
            mt: 1,
          },
        }}
        MenuListProps={{
          disablePadding: true,
          sx: {
            minWidth: '200px',
          },
        }}
    >
      <MenuItem
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            borderBottom: `1px solid ${theme.palette.divider}`,
            paddingTop: '20px',
            paddingBottom: '20px',
          }}
          onClick={() => {
            handleCloseMenu();
          }}
      >
        <Typography variant={'body1'}>{`${userObject?.firstName ||
        ''} ${userObject?.lastName || ''}`}</Typography>
        <Typography variant={'caption'}>{userObject?.email}</Typography>
      </MenuItem>

      <MenuItem
          sx={{
            paddingTop: '16px',
            paddingBottom: '16px',
          }}
          onClick={() => {
            handleCloseMenu();
            handleNavigate();
          }}
      >
        <Typography variant={'button'}>{t(
            'customerProfile.tabs.profile')}</Typography>
      </MenuItem>
      <MenuItem
          sx={{
            paddingTop: '16px',
            paddingBottom: '16px',
          }}
          onClick={() => {
            handleCloseMenu();
            handleUserLogout();
          }}
      >
        <Typography variant={'button'}>{t('authFlow.logout')}</Typography>
      </MenuItem>

    </Menu>

  </Box>);
}

export default SiteUserProfile;
