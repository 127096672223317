import {Route} from 'react-router-dom';
import AccessProtected from '../../access/AccessProtected';
import {
  CAPABILITY_READ_ALL,
  CAPABILITY_UPDATE,
} from '../../constants/capabilities';
import {MODULE_POLICIES} from '../../constants/modules';
import { lazy } from 'react';
const  PageAddEditClubPolicies = lazy(()=> import( '../../pages/Policies/PageAddEditClubPolicies'));
const  PagePoliciesDetail = lazy(()=> import( '../../pages/Policies/PagePoliciesDetail'));

const RoutesPolicies = (
    <>
    {/*READ_ALL Policies*/}
    <Route element={<AccessProtected
        capabilities={CAPABILITY_READ_ALL}
        module={MODULE_POLICIES}
    />}>
         <Route
            path={`/settings/policies`}
            element={<PagePoliciesDetail/>}
        />
    </Route>
    <Route element={<AccessProtected 
        capabilities={CAPABILITY_UPDATE}
        module={MODULE_POLICIES}
    />}>
    <Route 
        path={`/settings/policies/edit`}
        element={<PageAddEditClubPolicies/>}
        />
    </Route>

</>
)

export default RoutesPolicies;
