import {Route} from 'react-router-dom';
import AccessProtected from '../../access/AccessProtected';
import {CAPABILITY_READ_ALL} from '../../constants/capabilities';
import {MODULE_BOOKINGS} from '../../constants/modules';
import { lazy } from 'react';
const  PageSchedule = lazy(()=> import( '../../pages/Schedule/PageSchedule'));
const RoutesSchedule = (
    <>
      <Route element={<AccessProtected
          capabilities={CAPABILITY_READ_ALL}
          module={MODULE_BOOKINGS}
          redirectPath={'/restricted'}
      />}>
        <Route path="/*" element={<PageSchedule root={'/'}/>}/>
      </Route>

      <Route element={<AccessProtected
          capabilities={CAPABILITY_READ_ALL}
          module={MODULE_BOOKINGS}
      />}>
        <Route path={`/schedule/*`} element={
          <PageSchedule root={`/schedule`}/>}
        />
      </Route>
    </>
);

export default RoutesSchedule;
