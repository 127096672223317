import {useTranslate} from '@tolgee/react';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import {LayoutMainNavbarNav} from './LayoutMainNavbarNav';
import SettingsIcon from '@mui/icons-material/Settings';
import WorkIcon from '@mui/icons-material/Work';
import PeopleIcon from '@mui/icons-material/People';
import GroupIcon from '@mui/icons-material/Group';

export function LayoutMainNavbarSuperAdmin() {

  const {t} = useTranslate();

  const navItems = [
    {
      name: t('nav.companies'),
      route: `/companies`,
      icon: CalendarTodayIcon,
      disabled: false,
      canAccess: true,
    },
    {
      name: t('nav.clubs'),
      route: `/clubs`,
      icon: CalendarTodayIcon,
      disabled: false,
      canAccess: true,
    },
    {
      name: t('sideBar.link.textCustomer'),
      route: `/customers`,
      icon: GroupIcon,
      disabled: false,
      canAccess: false,
    },
    {
      icon: PeopleIcon,
      name: t('nav.link.companyAdmins'),
      route: `/company-admins`,
      disabled: false,
      canAccess: true,
    },
    {
      name: t('sideBar.link.textSetting'),
      route: `/settings`,
      icon: SettingsIcon,
      disabled: false,
      canAccess: true,
      subMenuItems: [
        {
          icon: WorkIcon,
          name: t('nav.profile'),
          route: `/settings/profile`,
          disabled: false,
          canAccess: true,
        },
        {
          icon: WorkIcon,
          name: t('nav.manageSuperAdmins'),
          route: `/settings/super-admins`,
          disabled: false,
          canAccess: true,
        },
      ],
    },
  ];

  return <LayoutMainNavbarNav navItems={navItems}/>;
}

