import { lazy } from 'react';
import {Route} from 'react-router-dom';
const PageCompanyListSuperAdmin = lazy(() => import('../../pages/Company/PageCompanyListSuperAdmin'));
const PageCompanyDetailSuperAdmin = lazy(() => import('../../pages/Company/PageCompanyDetailSuperAdmin'));
const PageCompanyAddEdit = lazy(() => import('../../pages/Company/PageCompanyAddEdit'));


const RoutesSuperAdminCompanies = (
    <>
      <Route
          path={'/'}
          element={<PageCompanyListSuperAdmin/>}
      />

      {/*Companies List*/}
      <Route
          path={'/companies'}
          element={<PageCompanyListSuperAdmin/>}
      />
      {/*Company Detail*/}
      <Route
          path={'/companies/detail/:companyId'}
          element={<PageCompanyDetailSuperAdmin tab={0}/>}
      />
      <Route
          path={'/companies/detail/:companyId/info'}
          element={<PageCompanyDetailSuperAdmin tab={0}/>}
      />
      <Route
          path={'/companies/detail/:companyId/clubs'}
          element={<PageCompanyDetailSuperAdmin tab={1}/>}
      />

      {/*CREATE COMPANY*/}
      <Route
          path={'/companies/add'}
          element={<PageCompanyAddEdit mode={'add'} role={'super_admin'}/>}
      />
      {/*UPDATE COMPANY*/}
      <Route
          path={'/companies/edit/:companyId'}
          element={<PageCompanyAddEdit mode={'edit'} role={'super_admin'}/>}
      />
    </>
)


export default RoutesSuperAdminCompanies;