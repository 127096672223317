export function getHasCapability(userPermissions = [], capability, module) {
  return userPermissions?.some(
      up => up.permission === capability && up.module.name === module,
  );
}

export function getHasCapabilities(
    userPermissions = [], capabilities = [], module) {
  const moduleCapabilities = userPermissions?.filter(
      up => up.module.name === module,
  );
  return capabilities?.every(
      c => moduleCapabilities?.some(mc => mc.permission === c),
  );
}


