import {Outlet} from 'react-router-dom';
// import LayoutMainHeader from './LayoutMainHeader';
import {Box} from '@mui/material';
import LayoutMainHeader from '../LayoutMain/LayoutMainHeader';
import LayoutMainPage from '../LayoutMain/LayoutMainPage';

function LayoutTemparoryMain({role}) {

  return (
      <Box
          sx={{height: '100vh', overflow: 'hidden'}}
      >
        <LayoutMainHeader role={role} showBottomLayout={false}/>
        <LayoutMainPage>
          <Outlet/>
        </LayoutMainPage>
      </Box>
  );
}

export default LayoutTemparoryMain;
