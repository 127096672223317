import {getHasCapability} from '../../utils/access';
import useUser from './useUser';

function useHasCapability(capability, module) {

  const user = useUser();
  const userPermissions = user?.userObj?.adminRole?.permissions;
  
  if (userPermissions){
    return getHasCapability(userPermissions, capability, module);
  }else {
    return false;
  }
}

export default useHasCapability;
