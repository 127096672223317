import { lazy } from 'react';
import {Route} from 'react-router-dom';
const PageClubsList = lazy(() => import('../../pages/Clubs/PageClubsList'));
const PageClubsDetailSuperAdmin = lazy(() => import('../../pages/Clubs/PageClubsDetailSuperAdmin'));
const PageClubsAddEdit = lazy(() => import('../../pages/Clubs/PageClubsAddEdit'));


const RoutesSuperAdminClubs = (
    <>
      <Route
          path={'/clubs/'}
          element={<PageClubsList role={'super_admin'}
                                  type={'all_company_child'}/>}
      />
      {/*Clubs Detail*/}
      <Route
          path={'/clubs/detail/:clubId'}
          element={<PageClubsDetailSuperAdmin type={'all_company_child'}/>}
      />
      <Route
          path={'/companies/:companyId/clubs/detail/:clubId'}
          element={<PageClubsDetailSuperAdmin type={'single_company_child'}/>}
      />
      {/*Clubs Add*/}
      <Route
          path={'/clubs/add'}
          element={<PageClubsAddEdit mode={'add'} role={'super_admin'}
                                     type={'all_company_child'}/>}
      />
      <Route
          path={'/companies/:companyId/clubs/add'}
          element={<PageClubsAddEdit mode={'add'} role={'super_admin'}
                                     type={'single_company_child'}/>}
      />
      {/*Clubs Edit*/}
      <Route
          path={'/clubs/edit/:clubId'}
          element={<PageClubsAddEdit
              mode={'edit'}
              role={'super_admin'}
              type={'all_company_child'}
          />}
      />
      <Route
          path={'companies/:companyId/clubs/:clubId/edit'}
          element={<PageClubsAddEdit
              mode={'edit'}
              role={'super_admin'}
              type={'single_company_child'}
          />}
      />
    </>
)

export default RoutesSuperAdminClubs;