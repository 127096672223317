import {Route} from 'react-router-dom';
import AccessProtected from '../../access/AccessProtected';
import {
  CAPABILITY_READ,
  CAPABILITY_READ_ALL,
} from '../../constants/capabilities';
import {MODULE_BOOKINGS} from '../../constants/modules';
import { lazy } from 'react';
const  PageBookingsList = lazy(()=> import( '../../pages/Bookings/PageBookingsList'));
const  PageBookingsDetailReservation = lazy(()=> import( '../../pages/Bookings/PageBookingsDetailReservation'));
const  PageBookingsNonReservationDetail = lazy(()=> import( '../../pages/Bookings/PageBookingsDetailNonReservation'));
const  PageBookingsDetailRecurring = lazy(()=> import( '../../pages/Bookings/PageBookingsDetailRecurring'));
const  BookingsDetailRecurringSingle = lazy(()=> import( '../../pages/Bookings/PageBookingsDetailRecurringSingle'));
const  PageBookingsDetailEvent = lazy(()=> import( '../../pages/Bookings/PageBookingsDetailEvent'));
const  PageBookingsAddEditRegular = lazy(()=> import( '../../pages/Bookings/PageBookingsAddEditRegular'));
const  PageBookingsAddEditRecurring = lazy(()=> import( '../../pages/Bookings/PageBookingsAddEditRecurring'));


const RoutesBookings = (
    <>
      {/*READ_ALL BOOKINGS*/}
      <Route element={<AccessProtected
          capabilities={CAPABILITY_READ_ALL}
          module={MODULE_BOOKINGS}
      />}>
        <Route path={'/bookings'} element={<PageBookingsList tab={0}/>}/>
        <Route path={'/bookings/active'}
               element={<PageBookingsList tab={0}/>}/>
        <Route path={'/bookings/recurring'}
               element={<PageBookingsList tab={1}/>}/>
        <Route path={'/bookings/past'} element={<PageBookingsList tab={2}/>}/>
      </Route>

      {/*READ BOOKINGS*/}
      <Route element={<AccessProtected
          capabilities={CAPABILITY_READ}
          module={MODULE_BOOKINGS}
      />}>
        <Route path={'/bookings/reservation/detail'}
               element={<PageBookingsDetailReservation/>}/>

        <Route path={'/bookings/non-reservation/detail'}
               element={<PageBookingsNonReservationDetail/>}/>

        <Route path={'/bookings/recurring/recurrence-detail/:id/*'}
               element={<PageBookingsDetailRecurring/>}/>

        <Route path={'/bookings/recurring/detail/'}
               element={<BookingsDetailRecurringSingle/>}/>
        <Route path={'/bookings/event/detail'}
               element={<PageBookingsDetailEvent/>}/>

      </Route>

      {/*CREATE BOOKINGS*/}
      <Route element={<AccessProtected
          capabilities={CAPABILITY_READ}
          module={MODULE_BOOKINGS}
      />}>
        <Route path={'/bookings/regular/add'}
               element={<PageBookingsAddEditRegular/>}/>
        <Route path={'/bookings/reservation/edit'}
               element={<PageBookingsDetailReservation/>}/>
      </Route>

      <Route element={<AccessProtected
          capabilities={CAPABILITY_READ}
          module={MODULE_BOOKINGS}
      />}>
        <Route path={'/bookings/recurring/add'}
               element={<PageBookingsAddEditRecurring/>}/>
        <Route path={'/bookings/recurring/edit'}
               element={<PageBookingsAddEditRecurring/>}/>
      </Route>
    </>
);
export default RoutesBookings;
