import {Outlet} from 'react-router-dom';
import LayoutMainHeader from './LayoutMainHeader';
import LayoutMainPage from './LayoutMainPage';
import {Box} from '@mui/material';
import { Suspense } from 'react';
import SuspensePageLoadFallback from '../../components/_Global/Fallback/SuspensePageLoadFallback';

function LayoutMain({role}) {

  return (
      <Box
          sx={{height: '100vh', overflow: 'hidden'}}
      >
        <LayoutMainHeader role={role}/>
        <Suspense fallback={<SuspensePageLoadFallback />}>
        <LayoutMainPage>
          <Outlet/>
        </LayoutMainPage>
        </Suspense>
      </Box>
  );
}

export default LayoutMain;
