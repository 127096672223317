import {Box, Stack, Typography, useTheme} from '@mui/material';
import {useTranslate} from '@tolgee/react';
import ContainerFullWidth from '../Containers/ContainerFullWidth';
import LogoTypeMeetAndPlay from '../../../assets/logos/LogoTypeMeetAndPlay';
import LogoPalla from '../../../assets/logos/LogoPalla';

function SiteFooter() {

  const theme = useTheme();
  const {t} = useTranslate();

  return (
      <Box
          sx={{
            borderTop:`1px solid rgba(0,0,0,0.1)`,
            padding: '24px 16px',
            backgroundColor: '#fff',
            width: '100%',
            mt: 'auto',
          }}
      >
        <ContainerFullWidth>
          <FooterContent/>
        </ContainerFullWidth>

      </Box>
  );
}

function FooterContent() {

  const theme = useTheme();
  const {t} = useTranslate();
  const poweredBy = process.env.REACT_APP_ENV_DOMAIN === 'MEETANDPLAYNL' ? 'Meet & Play Club' : 'Palla';
  return (
      <Stack direction={'row'} justifyContent={'space-between'}
             width={'100'} alignItems={'center'}>

        <Stack
            direction={'row'}
            alignItems={'center'}
            spacing={1}
            sx={{
              px: 2,
              py: 1,
              backgroundColor: theme.palette.grey[100],
              borderRadius: '34px',
            }}
        >
          <Typography
              whiteSpace={'nowrap'}
              fontSize={'12px'}
          >
            {t('site.footer.poweredBy')}
          </Typography>
         { process.env.REACT_APP_ENV_DOMAIN === 'MEETANDPLAYNL' ? <LogoTypeMeetAndPlay/> : 
         <LogoPalla />
         }
        </Stack>

        <Typography variant={'body2'}>
          © {t('footer.text')} {poweredBy} {t('footer.textSub')}
        </Typography>
      </Stack>
  );
}


export default SiteFooter;
