import {Box, Stack, Typography, useTheme} from '@mui/material';
import {NavLink, useLocation} from 'react-router-dom';
import {LAYOUT_MAIN__HEADER__BOTTOM__HEIGHT} from '../../constants/ui';
import ContainerFullWidth
  from '../../components/_Global/Containers/ContainerFullWidth';
import {LayoutMainNavbarNavSubMenu} from './LayoutMainNavbarNavSubMenu';

export function LayoutMainNavbarNav({navItems,onClick=()=>{}}) {

  const theme = useTheme();
  const location = useLocation();
  const isHome = location.pathname === '/';

  return <Box
      sx={{
        borderBottom: `1px solid ${theme.palette.divider}`,
        height: LAYOUT_MAIN__HEADER__BOTTOM__HEIGHT,
        display: 'flex',
        alignItems: 'center',
      }}
  >
    <ContainerFullWidth>
      <Stack
          direction={'row'}
          spacing={3}
          alignItems={'center'}
          component={'ul'}
          sx={{
            padding: 0,
            height: LAYOUT_MAIN__HEADER__BOTTOM__HEIGHT,
          }}
      >
        {
          navItems.map((listItem, i) => {

            const submenuItems = listItem?.subMenuItems;

            return (
                listItem.canAccess &&
                <Box
                    key={i}
                    component={'li'}
                    sx={{
                      listStyle: 'none',
                      display: 'flex',
                      position: 'relative',
                    }}
                >
                  <Stack
                      component={NavLink}
                      to={listItem.route}
                      onClick={()=>listItem?.onClick? listItem?.onClick() : onClick()}
                      end={true}
                      direction={'row'}
                      alignItems={'center'}
                      color={'text.primary'}
                      className={`${listItem.disabled &&
                      'disabled'} ${listItem.activeOnRoot && isHome &&
                      'active'}`}
                      sx={{
                        textDecoration: 'none',
                        fontSize: '14px',
                        fontWeight: 500,
                        padding: '5px 12px',
                        borderRadius: '30px',
                        '&.disabled': {
                          color: `${theme.palette.text.disabled} !important`,
                          pointerEvents: 'none',
                          cursor: 'none',
                        },
                        '&.active, &.active:hover': {
                          backgroundColor: '#D9EAFF',
                        },
                        '&:hover': {
                          backgroundColor: theme.palette.grey[50],
                        },
                      }}
                  >
                    <Typography fontSize={'inherit'} fontWeight={'inherit'}>
                      {listItem.name}
                    </Typography>
                  </Stack>
                  {
                      submenuItems &&
                      <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'text.secondary',
                        cursor: 'pointer',
                      }}>
                        <LayoutMainNavbarNavSubMenu items={submenuItems}/>
                      </Box>

                  }

                </Box>);
          })}
      </Stack>
    </ContainerFullWidth>
  </Box>;
}