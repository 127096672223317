import {Route} from 'react-router-dom';
import AccessProtected from '../../access/AccessProtected';
import {
  CAPABILITY_READ_ALL,
  CAPABILITY_UPDATE,
} from '../../constants/capabilities';
import { MODULE_MESHLINK} from '../../constants/modules';
import { lazy } from 'react';
const  PageEditMeshlink = lazy(()=> import( '../../pages/Mashlink/PageEditMeshlink'));
const  PageMeshlink = lazy(()=> import( '../../pages/Mashlink/PageMeshlink'));

const RoutesMeshlink = (
    <>
      <Route path={`/integration/meshlink`}>
        {/*READ_ALL EXTRAS*/}
        <Route element={<AccessProtected
            capabilities={CAPABILITY_READ_ALL}
            module={MODULE_MESHLINK} 
            isIntegrations={true}
        />}>
          <Route index element={<PageMeshlink/>}/>
        </Route>
        <Route element={<AccessProtected
            capabilities={CAPABILITY_UPDATE}
            module={MODULE_MESHLINK} 
        />}>
          <Route path='edit' element={<PageEditMeshlink/>}/>
        </Route>

      </Route>
    </>
);

export default RoutesMeshlink;
