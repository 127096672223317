import {Route} from 'react-router-dom';
import AccessProtected from '../../access/AccessProtected';
import {
  CAPABILITY_CREATE,
  CAPABILITY_READ,
  CAPABILITY_READ_ALL,
  CAPABILITY_UPDATE,
} from '../../constants/capabilities';
import {MODULE_EVENTS} from '../../constants/modules';
import { lazy } from 'react';
const  PageEventsList = lazy(()=> import( '../../pages/Events/PageEventsList'));
const  PageEventsAddEdit = lazy(()=> import( '../../pages/Events/PageEventsAddEdit'));
const  PageEventsDetail = lazy(()=> import( '../../pages/Events/PageEventsDetail'));


const RoutesEvents = (
    <>
      {/*EVENTS*/}
      <Route path={`/events`}>
        <Route element={<AccessProtected
            capabilities={CAPABILITY_READ_ALL}
            module={MODULE_EVENTS}
        />}>
          <Route index element={<PageEventsList tab={0}/>}/>
          <Route path={'active'} element={<PageEventsList tab={0}/>}/>
          <Route path={'past'} element={<PageEventsList tab={1}/>}/>
        </Route>

        {/*CREATE EVENT*/}
        <Route element={<AccessProtected
            capabilities={CAPABILITY_CREATE}
            module={MODULE_EVENTS}
        />}>
          <Route path={`add`} element={<PageEventsAddEdit/>}/>
        </Route>
        {/*UPDATE EVENT*/}
        <Route element={<AccessProtected
            capabilities={CAPABILITY_UPDATE}
            module={MODULE_EVENTS}
        />}>
          <Route path={`edit`} element={<PageEventsAddEdit/>}/>
        </Route>
        <Route element={<AccessProtected
            capabilities={CAPABILITY_READ}
            module={MODULE_EVENTS}
        />}>
          <Route path={`*`} element={<PageEventsDetail/>}/>
        </Route>
      </Route>
    </>
);

export default RoutesEvents;
