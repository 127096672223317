import {SnackbarProvider} from 'notistack';
import {styled} from '@mui/material';
import SnackbarCloseButton from '../vendor/notistack/SnackbarCloseButton';

const StyledSnackbarProvider = styled(SnackbarProvider)(({theme}) => {
  return {
    '&.SnackbarItem': {
      '&-variant': {
        '&Success': {
          backgroundColor: theme.palette.success.main,
        },
        '&Error': {
          backgroundColor: theme.palette.error.main,
        },
        '&Info': {
          backgroundColor: theme.palette.info.main,
        },
        '&Warning': {
          backgroundColor: theme.palette.warning.main,
        },
      },
    },
  };
});

function ProviderNotistack({children}) {
  return <StyledSnackbarProvider
      autoHideDuration={5000}
      preventDuplicate
      disableWindowBlurListener
      maxSnack={1}
      action={(snackbarKey) => {
        return <SnackbarCloseButton snackbarKey={snackbarKey}/>;
      }}
      anchorOrigin={{
        horizontal: 'right', vertical: 'top',
      }}
  >
    {children}
  </StyledSnackbarProvider>;
}

export default ProviderNotistack;
