import {Route} from 'react-router-dom';
import AccessProtected from '../../access/AccessProtected';
import {CAPABILITY_READ_ALL} from '../../constants/capabilities';
import {MODULE_PRICING} from '../../constants/modules';
import { lazy } from 'react';
const  PagePricing = lazy(()=> import( '../../pages/Pricing/PagePricing'));

const RoutesSettingsPricing = (
    <>
        {/*READ_ALL PRICING*/}
        <Route element={<AccessProtected
            capabilities={CAPABILITY_READ_ALL}
            module={MODULE_PRICING}
        />}>
            <Route path={`/settings/pricing/*`} element={
                <PagePricing/>}/>
        </Route>
    </>
)

export default RoutesSettingsPricing;
