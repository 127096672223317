import {Route} from 'react-router-dom';
import AccessProtected from '../../access/AccessProtected';
import {
  CAPABILITY_READ,
  CAPABILITY_UPDATE,
} from '../../constants/capabilities';
import {MODULE_COMPANY} from '../../constants/modules';
import { lazy } from 'react';
const  PageCompanyDetail = lazy(()=> import( '../../pages/Company/PageCompanyDetail'));
const  PageCompanyAddEdit = lazy(()=> import( '../../pages/Company/PageCompanyAddEdit'));


const RoutesSettingsCompany = (
    <>
      <Route path={`/settings/company`}>
        {/*UPDATE COMPANY*/}
        <Route element={<AccessProtected
            capabilities={CAPABILITY_UPDATE}
            module={MODULE_COMPANY}
        />}>
          <Route path={`edit`} element={<PageCompanyAddEdit mode={'edit'} role={'club_manager'}/>}/>
        </Route>
        {/*READ COMPANY*/}
        <Route element={<AccessProtected
            capabilities={CAPABILITY_READ}
            module={MODULE_COMPANY}
        />}>
          <Route path={`detail`} element={<PageCompanyDetail/>}/>
        </Route>
      </Route>
    </>
);

export default RoutesSettingsCompany;
