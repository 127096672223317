import {statusError, statusLoading, statusSuccess} from './status';
import {
  GET_CITIES_FAILURE,
  GET_CITIES_LOADING,
  GET_CITIES_SUCCESS,
  GET_COUNTRY_FAILURE,
  GET_COUNTRY_LOADING,
  GET_COUNTRY_SUCCESS,
} from '../constants';

import axios from 'axios';

export const locationsCountriesList_GET = (cbSuccess, cbFail) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(GET_COUNTRY_LOADING));
      const res = await axios.get(`locations/countries/list?limit=1000`);
      dispatch(statusSuccess(GET_COUNTRY_SUCCESS, res.data.data));
      cbSuccess(res);
    } catch (e) {
      dispatch(statusError(GET_COUNTRY_FAILURE, e));
      cbFail(e);
    }
  };
};

export const locationsCitiesList_GET = (id, cbSuccess, cbFail) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(GET_CITIES_LOADING));
      const response = await axios.get(`locations/cities/list/${id}`);
      dispatch(statusSuccess(GET_CITIES_SUCCESS, response.data.data));
      cbSuccess(response);
    } catch (e) {
      dispatch(statusError(GET_CITIES_FAILURE, e));
      cbFail(e);
    }
  };
};
