import * as Sentry from '@sentry/react';

function ProviderSentry({children}) {
  const env = process.env.REACT_APP_ENV;
  const isLocal = env === 'local';

  if (!isLocal) {
    Sentry.init({
      environment: env,
      dsn: 'https://c445a04be401452c9964715e09118d54@o106386.ingest.sentry.io/4504723022479360',
      // integrations: [new Sentry.BrowserTracing()],
      tracesSampleRate: 0,
    });
  }
  return <Sentry.ErrorBoundary>
    {children}
  </Sentry.ErrorBoundary>;
}

export default ProviderSentry;
