import {Route} from 'react-router-dom';
import AccessProtected from '../../access/AccessProtected';
import {CAPABILITY_READ_ALL} from '../../constants/capabilities';
import {MODULE_HOLIDAYS} from '../../constants/modules';
import { lazy } from 'react';
const  PageHolidaysList = lazy(()=> import( '../../pages/Holidays/PageHolidaysList'));

const RoutesSettingsHolidays = (
    <>
      <Route element={<AccessProtected
          capabilities={CAPABILITY_READ_ALL}
          module={MODULE_HOLIDAYS}
      />}>
        <Route path={'/settings/holidays/*'}
               element={<PageHolidaysList/>}/>
      </Route>
    </>
);

export default RoutesSettingsHolidays;
