import { lazy } from 'react';
import {Route} from 'react-router-dom';
const PageSuperAdminsList = lazy(() => import('../../pages/SuperAdmins/PageSuperAdminsList'));
const PageSuperAdminDetail = lazy(() => import('../../pages/SuperAdmins/PageSuperAdminDetail'));
const PageSuperAdminAddEdit = lazy(() => import('../../pages/SuperAdmins/PageSuperAdminAddEdit'));


const RoutesSuperAdminSuperAdmins = (
    <>
      <Route path={'/settings/super-admins'}>
        <Route index element={<PageSuperAdminsList/>}/>
        <Route path={'detail/:id'} element={<PageSuperAdminDetail/>}/>
        <Route path={'edit/:id'} element={<PageSuperAdminAddEdit mode={'edit'}/>}/>
      </Route>
    </>
)

export default RoutesSuperAdminSuperAdmins;