import {Route} from 'react-router-dom';
import AccessProtected from '../../access/AccessProtected';
import {
  CAPABILITY_CREATE, CAPABILITY_READ,
  CAPABILITY_READ_ALL, CAPABILITY_UPDATE,
} from '../../constants/capabilities';
import {MODULE_ADMINS} from '../../constants/modules';
import { lazy } from 'react';
const PageManageAdminsList = lazy(() => import('../../pages/ManageAdmins/PageManageAdminsList'));
const PageManageAdminsAddEdit = lazy(() => import('../../pages/ManageAdmins/PageManageAdminsAddEdit'));
const PageManageAdminsDetail = lazy(() => import('../../pages/ManageAdmins/PageManageAdminsDetail'));


const RoutesSuperAdminCompanyAdmins = (
    <>
      <Route path={`/company-admins`}>
        {/*READ_ALL ADMINS*/}
        <Route element={<AccessProtected
            capabilities={CAPABILITY_READ_ALL}
            module={MODULE_ADMINS}
        />}>
          <Route index element={<PageManageAdminsList role={'super_admin'} />}/>
        </Route>
        {/*ADD ADMINS*/}
        <Route element={<AccessProtected
            capabilities={CAPABILITY_CREATE}
            module={MODULE_ADMINS}
        />}>
          <Route path={`add`} element={<PageManageAdminsAddEdit mode={'add'}
                                                                role={'super_admin'}/>}/>
        </Route>
        {/*UPDATE ADMINS*/}
        <Route element={<AccessProtected
            capabilities={CAPABILITY_UPDATE}
            module={MODULE_ADMINS}
        />}>
          <Route path={`edit`} element={<PageManageAdminsAddEdit mode={'edit'}
                                                                 role={'super_admin'}/>}/>
        </Route>
        {/*READ ADMINS*/}
        <Route element={<AccessProtected
            capabilities={CAPABILITY_READ}
            module={MODULE_ADMINS}
        />}>
          <Route path={`*`} element={<PageManageAdminsDetail role={'super_admin'}/>}/>
        </Route>
      </Route>
    </>
)

export default RoutesSuperAdminCompanyAdmins;