import {useSelector} from 'react-redux';
import {
  APP_DEFAULT_CURRENCY_NAME,
  APP_DEFAULT_CURRENCY_SYMBOL,
  APP_DEFAULT_LANGUAGE,
  APP_DEFAULT_LOCALE,
} from '../../constants/app-defaults';

function useDefaults() {

  const companyDefaults = useSelector((state) => state.app.companyDefaults);

  return {
    defaultLocale: companyDefaults?.locale?.key || APP_DEFAULT_LOCALE,
    defaultLanguage: companyDefaults?.locale?.language || APP_DEFAULT_LANGUAGE,
    defaultCurrency: companyDefaults?.currency?.name || APP_DEFAULT_CURRENCY_NAME,
    defaultCurrencySymbol: companyDefaults?.currency?.symbol ||
        APP_DEFAULT_CURRENCY_SYMBOL,
  };

}

export default useDefaults;
