import {Route} from 'react-router-dom';
import AccessProtected from '../../access/AccessProtected';
import {
  CAPABILITY_CREATE,
  CAPABILITY_READ,
  CAPABILITY_READ_ALL,
  CAPABILITY_UPDATE,
} from '../../constants/capabilities';
import {MODULE_COURTS} from '../../constants/modules';
import { lazy } from 'react';
const  PageCourtsAddEdit = lazy(()=> import( '../../pages/Courts/PageCourtsAddEdit'));
const  PageCourtsList = lazy(()=> import( '../../pages/Courts/PageCourtsList'));
const  PageCourtsDetail = lazy(()=> import( '../../pages/Courts/PageCourtsDetail'));

const RoutesSettingsCourts = (
    <>
      <Route path={`/settings/courts`}>

        {/*READ_ALL COURTS*/}
        <Route element={<AccessProtected
            capabilities={CAPABILITY_READ_ALL}
            module={MODULE_COURTS}
        />}>
          <Route index element={<PageCourtsList/>}/>
        </Route>

        {/*READ COURTS*/}
        <Route element={<AccessProtected
            capabilities={CAPABILITY_READ}
            module={MODULE_COURTS}
        />}>
          <Route path="detail/:id" element={<PageCourtsDetail/>}/>
        </Route>

        {/*CREATE COURTS*/}
        <Route element={<AccessProtected
            capabilities={CAPABILITY_CREATE}
            module={MODULE_COURTS}
        />}>
          <Route path={`add`} element={<PageCourtsAddEdit mode={'add'}/>}/>
        </Route>

        {/*UPDATE COURTS*/}
        <Route element={<AccessProtected
            capabilities={CAPABILITY_UPDATE}
            module={MODULE_COURTS}
        />}>
          <Route path={`edit`}
                 element={<PageCourtsAddEdit mode={'edit'}/>}/>
        </Route>


      </Route>
    </>
);

export default RoutesSettingsCourts;
