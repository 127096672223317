import {Box} from '@mui/material';

function ContainerFullWidth({children}) {
  return <Box
      sx={{
        margin: '0 auto',
        maxWidth: '100%',
        width: '100%',
        padding: '0 24px',
      }}
  >
    {children}
  </Box>;
}

export default ContainerFullWidth;
