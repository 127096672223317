import {Route} from 'react-router-dom';
import AccessProtected from '../../access/AccessProtected';
import {CAPABILITY_READ_ALL} from '../../constants/capabilities';
import {MODULE_FINANCIALS} from '../../constants/modules';
import { lazy } from 'react';
const  PageFinances = lazy(()=> import( '../../pages/Finances/PageFinances'));

const RoutesFinancials = (
    <>
        {/*READ_ALL FINANCIALS*/}
        <Route element={<AccessProtected
            capabilities={CAPABILITY_READ_ALL}
            module={MODULE_FINANCIALS}
        />}>
            <Route
                path={`/finances`}
                element={<PageFinances tab={0}/>}
            />
            <Route
                path={`/finances/transactions`}
                element={<PageFinances tab={0}/>}
            />
        </Route>

    </>
)

export default RoutesFinancials;
