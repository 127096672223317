//CLUBS
export const CLUBS_GENERIC_CREATE_SUCCESS = 'CLUBS_GENERIC_CREATE_SUCCESS';
export const CLUBS_GENERIC_CREATE_LOADING = 'CLUBS_GENERIC_CREATE_LOADING';
export const CLUBS_GENERIC_CREATE_FAILURE = 'CLUBS_GENERIC_CREATE_FAILURE';

export const CLUBS_DETAIL_BY_ID_GET_SUCCESS = 'CLUBS_DETAIL_BY_ID_GET_SUCCESS';
export const CLUBS_DETAIL_BY_ID_GET_LOADING = 'CLUBS_DETAIL_BY_ID_GET_LOADING';
export const CLUBS_DETAIL_BY_ID_GET_FAILURE = 'CLUBS_DETAIL_BY_ID_GET_FAILURE';

export const CLUBS_SWITCHER_GET_SUCCESS = 'CLUBS_SWITCHER_GET_SUCCESS';
export const CLUBS_SWITCHER_GET_LOADING = 'CLUBS_SWITCHER_GET_LOADING';
export const CLUBS_SWITCHER_GET_FAILURE = 'CLUBS_SWITCHER_GET_FAILURE';

export const CLUBS_GENERIC_LIST_GET_SUCCESS = 'CLUBS_GENERIC_LIST_GET_SUCCESS';
export const CLUBS_GENERIC_LIST_GET_LOADING = 'CLUBS_GENERIC_LIST_GET_LOADING';
export const CLUBS_GENERIC_LIST_GET_FAILURE = 'CLUBS_GENERIC_LIST_GET_FAILURE';

export const CLUBS_INTEGRATION_LIST_GET_SUCCESS = 'CLUBS_INTEGRATION_LIST_GET_SUCCESS';
export const CLUBS_INTEGRATION_LIST_GET_LOADING = 'CLUBS_INTEGRATION_LIST_GET_LOADING';
export const CLUBS_INTEGRATION_LIST_GET_FAILURE = 'CLUBS_INTEGRATION_LIST_GET_FAILURE';

export const GET_CLUB_TIMING_SUCCESS = 'GET_CLUB_TIMING_SUCCESS';
export const GET_CLUB_TIMING_LOADING = 'GET_CLUB_TIMING_LOADING';
export const GET_CLUB_TIMING_FAILURE = 'GET_CLUB_TIMING_FAILURE';


export const CLUBS_GENERIC_EDIT_SUCCESS = 'CLUBS_GENERIC_EDIT_SUCCESS';
export const CLUBS_GENERIC_EDIT_LOADING = 'CLUBS_GENERIC_EDIT_LOADING';
export const CLUBS_GENERIC_EDIT_FAILURE = 'CLUBS_GENERIC_EDIT_FAILURE';


export const AMENITIES_LIST_SUCCESS = 'AMENITIES_LIST_SUCCESS';
export const AMENITIES_LOADING = 'AMENITIES_LOADING';
export const AMENITIES_LIST_FAILURE = 'AMENITIES_LIST_FAILURE';

export const SET_SELECTED_CLUB = 'SET_SELECTED_CLUB';
export const SET_COMPANY_DEFAULTS = 'SET_COMPANY_DEFAULTS';

//COMPANY
export const ADD_COMPANY_SUCCESS = 'ADD_COMPANY_SUCCESS';
export const ADD_COMPANY_LOADING = 'ADD_COMPANY_LOADING';
export const ADD_COMPANY_FAILURE = 'ADD_COMPANY_FAILURE';

export const EDIT_COMPANY_SUCCESS = 'EDIT_COMPANY_SUCCESS';
export const EDIT_COMPANY_LOADING = 'EDIT_COMPANY_LOADING';
export const EDIT_COMPANY_FAILURE = 'EDIT_COMPANY_FAILURE';

export const GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS';
export const GET_COMPANY_LOADING = 'GET_COMPANY_LOADING';
export const GET_COMPANY_FAILURE = 'GET_COMPANY_FAILURE';

export const GET_DOMAIN_SUCCESS = 'GET_DOMAIN_SUCCESS';
export const GET_DOMAIN_LOADING = 'GET_DOMAIN_LOADING';
export const GET_DOMAIN_FAILURE = 'GET_DOMAIN_FAILURE';

export const CONNECT_MOLLIE_SUCCESS = 'CONNECT_MOLLIE_SUCCESS';
export const CONNECT_MOLLIE_LOADING = 'CONNECT_MOLLIE_LOADING';
export const CONNECT_MOLLIE_FAILURE = 'CONNECT_MOLLIE_FAILURE';

//COURTS
export const COURTS_GENERIC_CREATE_POST_SUCCESS = 'COURTS_GENERIC_CREATE_POST_SUCCESS';
export const COURTS_GENERIC_CREATE_POST_LOADING = 'COURTS_GENERIC_CREATE_POST_LOADING';
export const COURTS_GENERIC_CREATE_POST_FAILURE = 'COURTS_GENERIC_CREATE_POST_FAILURE';

export const COURT_GENERIC_EDIT_PATCH_SUCCESS = 'COURT_GENERIC_EDIT_PATCH_SUCCESS';
export const COURTS_GENERIC_EDIT_PATCH_LOADING = 'COURTS_GENERIC_EDIT_PATCH_LOADING';
export const COURTS_GENERIC_EDIT_PATCH_FAILURE = 'COURTS_GENERIC_EDIT_PATCH_FAILURE';

export const GET_COURT_SUCCESS = 'GET_COURT_SUCCESS';
export const GET_COURT_LOADING = 'GET_COURT_LOADING';
export const GET_COURT_FAILURE = 'GET_COURT_FAILURE';

// packages

export const GET_PACKAGES_SUCCESS = 'GET_PACKAGES_SUCCESS';
export const GET_PACKAGES_LOADING = 'GET_PACKAGES_LOADING';
export const GET_PACKAGES_FAILURE = 'GET_PACKAGES_FAILURE';

//extras
export const EXTRAS_SUCCESS = 'EXTRAS_SUCCESS';
export const EXTRAS_LOADING = 'EXTRAS_LOADING';
export const EXTRAS_FAILURE = 'EXTRAS_FAILURE';
// BOOKINGS
export const BOOKINGS_LIST_GET_SUCCESS = 'BOOKINGS_LIST_GET_SUCCESS';
export const BOOKINGS_LIST_GET_LOADING = 'BOOKINGS_LIST_GET_LOADING';
export const BOOKINGS_LIST_GET_FAILURE = 'BOOKINGS_LIST_GET_FAILURE';



// BOOKING
export const BOOKINGS_BY_ID_GET_SUCCESS = 'BOOKINGS_BY_ID_GET_SUCCESS';
export const BOOKINGS_BY_ID_GET_LOADING = 'BOOKINGS_BY_ID_GET_LOADING';
export const BOOKINGS_BY_ID_GET_FAILURE = 'BOOKINGS_BY_ID_GET_FAILURE';



// BOOKINGS CREATE
export const BOOKINGS_CREATE_POST_SUCCESS = 'BOOKINGS_CREATE_POST_SUCCESS';
export const BOOKINGS_CREATE_POST_LOADING = 'BOOKINGS_CREATE_POST_LOADING';
export const BOOKINGS_CREATE_POST_FAILURE = 'BOOKINGS_CREATE_POST_FAILURE';




// BOOKINGS CANCEL
export const BOOKINGS_CANCEL_POST_SUCCESS = 'BOOKINGS_CANCEL_POST_SUCCESS';
export const BOOKINGS_CANCEL_POST_LOADING = 'BOOKINGS_CANCEL_POST_LOADING';
export const BOOKINGS_CANCEL_POST_FAILURE = 'BOOKINGS_CANCEL_POST_FAILURE';


// BOOKING TYPES
export const BOOKINGS_TYPES_GET_SUCCESS = 'BOOKINGS_TYPES_GET_SUCCESS';
export const BOOKINGS_TYPES_GET_LOADING = 'BOOKINGS_TYPES_GET_LOADING';
export const BOOKINGS_TYPES_GET_FAILURE = 'BOOKINGS_TYPES_GET_FAILURE';

// BOOKING EXTRAS
export const BOOKINGS_EXTRAS_GET_SUCCESS = 'BOOKINGS_EXTRAS_GET_SUCCESS';
export const BOOKINGS_EXTRAS_GET_LOADING = 'BOOKINGS_EXTRAS_GET_LOADING';
export const BOOKINGS_EXTRAS_GET_FAILURE = 'BOOKINGS_EXTRAS_GET_FAILURE';


//FINANCIALS
export const FINANCIALS_LIST_GET_SUCCESS = 'FINANCIALS_LIST_GET_SUCCESS';
export const FINANCIALS_LIST_GET_LOADING = 'FINANCIALS_LIST_GET_LOADING';
export const FINANCIALS_LIST_GET_FAILURE = 'FINANCIALS_LIST_GET_FAILURE';

// COURT PRICE
export const BOOKINGS_CALCULATE_PRICE_POST_SUCCESS = 'BOOKINGS_CALCULATE_PRICE_POST_SUCCESS';
export const BOOKINGS_CALCULATE_PRICE_POST_LOADING = 'BOOKINGS_CALCULATE_PRICE_POST_LOADING';
export const BOOKINGS_CALCULATE_PRICE_POST_FAILURE = 'BOOKINGS_CALCULATE_PRICE_POST_FAILURE';


// AVAILABLE COURTS
export const BOOKINGS_AVAILABLE_COURTS_GET_SUCCESS = 'BOOKINGS_AVAILABLE_COURTS_GET_SUCCESS';
export const BOOKINGS_AVAILABLE_COURTS_GET_LOADING = 'BOOKINGS_AVAILABLE_COURTS_GET_LOADING';
export const BOOKINGS_AVAILABLE_COURTS_GET_FAILURE = 'BOOKINGS_AVAILABLE_COURTS_GET_FAILURE';

//EVENTS
export const EVENTS_SUCCESS = 'EVENTS_SUCCESS';
export const EVENTS_LOADING = 'EVENTS_LOADING';
export const EVENTS_FAILURE = 'EVENTS_FAILURE';

// PLAYERS
export const BOOKINGS_CUSTOMERS_GET_SUCCESS = 'BOOKINGS_CUSTOMERS_GET_SUCCESS';
export const BOOKINGS_CUSTOMERS_GET_LOADING = 'BOOKINGS_CUSTOMERS_GET_LOADING';
export const BOOKINGS_CUSTOMERS_GET_FAILURE = 'BOOKINGS_CUSTOMERS_GET_FAILURE';

//
export const SIDEPANEL_BOOKING_GET_SUCCESS = 'SIDEPANEL_BOOKING_GET_SUCCESS';
export const SIDEPANEL_BOOKING_GET_LOADING = 'SIDEPANEL_BOOKING_GET_LOADING';
export const SIDEPANEL_BOOKING_GET_FAILURE = 'SIDEPANEL_BOOKING_GET_FAILURE';

//Add PRICING
export const ADD_PRICING_SUCCESS = 'ADD_PRICING_SUCCESS';
export const ADD_PRICING_LOADING = 'ADD_PRICING_LOADING';
export const ADD_PRICING_FAILURE = 'ADD_PRICING_FAILURE';

// COURTS DAYS PRICING
export const GET_COURT_DAYS_PRICINGS_SUCCESS = 'GET_COURT_DAYS_PRICINGS_SUCCESS';
export const GET_COURT_DAYS_PRICINGS_LOADING = 'GET_COURT_DAYS_PRICINGS_LOADING';
export const GET_COURT_DAYS_PRICINGS_FAILURE = 'GET_COURT_DAYS_PRICINGS_FAILURE';

//Policies
export const POLICIES_SUCCESS = 'POLICIES_SUCCESS';
export const POLICIES_LOADING = 'POLICIES_LOADING';
export const POLICIES_FAILURE = 'POLICIES_FAILURE';

export const POLICIES_UPDATE_SUCCESS = 'POLICIES_UPDATE_SUCCESS';
export const POLICIES_UPDATE_LOADING = 'POLICIES_UPDATE_LOADING';
export const POLICIES_UPDATE_FAILURE = 'POLICIES_UPDATE_FAILURE';

// CUSTOMER
export const EDIT_CUSTOMER_SUCCESS = 'EDIT_CUSTOMER_SUCCESS';
export const EDIT_CUSTOMER_LOADING = 'EDIT_CUSTOMER_LOADING';
export const EDIT_CUSTOMER_FAILURE = 'EDIT_CUSTOMER_FAILURE';

export const ADD_CUSTOMER_SUCCESS = 'ADD_CUSTOMER_SUCCESS';
export const ADD_CUSTOMER_LOADING = 'ADD_CUSTOMER_LOADING';
export const ADD_CUSTOMER_FAILURE = 'ADD_CUSTOMER_FAILURE';

export const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS';
export const GET_CUSTOMER_LOADING = 'GET_CUSTOMER_LOADING';
export const GET_CUSTOMER_FAILURE = 'GET_CUSTOMER_FAILURE';

export const VALIDATE_CUSTOMER_SUCCESS = 'VALIDATE_CUSTOMER_SUCCESS';
export const VALIDATE_CUSTOMER_LOADING = 'VALIDATE_CUSTOMER_LOADING';
export const VALIDATE_CUSTOMER_FAILURE = 'VALIDATE_CUSTOMER_FAILURE';

export const GET_CUSTOMER_BYID_SUCCESS = 'GET_CUSTOMER_BYID_SUCCESS';
export const GET_CUSTOMER_BYID_LOADING = 'GET_CUSTOMER_BYID_LOADING';
export const GET_CUSTOMER_BYID_FAILURE = 'GET_CUSTOMER_BYID_FAILURE';

export const GET_CUSTOMER_BOOKING_SUCCESS = 'GET_CUSTOMER_BOOKING_SUCCESS';
export const GET_CUSTOMER_BOOKING_LOADING = 'GET_CUSTOMER_BOOKING_LOADING';
export const GET_CUSTOMER_BOOKING_FAILURE = 'GET_CUSTOMER_BOOKING_FAILURE';



export const GET_ADMINS_SUCCESS = 'GET_ADMINS_SUCCESS';
export const GET_ADMINS_LOADING = 'GET_ADMINS_LOADING';
export const GET_ADMINS_FAILURE = 'GET_ADMINS_FAILURE';

export const ADD_ADMINS_SUCCESS = 'ADD_ADMINS_SUCCESS';
export const ADD_ADMINS_LOADING = 'ADD_ADMINS_LOADING';
export const ADD_ADMINS_FAILURE = 'ADD_ADMINS_FAILURE';

//reports
export const GET_BOOKINGPERDAY_SUCCESS = 'GET_BOOKINGPERDAY_SUCCESS';
export const GET_BOOKINGPERDAY_LOADING = 'GET_BOOKINGPERDAY_LOADING';
export const GET_BOOKINGPERDAY_FAILURE = 'GET_BOOKINGPERDAY_FAILURE';

//COUNTRY
export const GET_COUNTRY_SUCCESS = 'GET_COUNTRY_SUCCESS';
export const GET_COUNTRY_LOADING = 'GET_COUNTRY_LOADING';
export const GET_COUNTRY_FAILURE = 'GET_COUNTRY_FAILURE';

export const GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS';
export const GET_CITIES_LOADING = 'GET_CITIES_LOADING';
export const GET_CITIES_FAILURE = 'GET_CITIES_FAILURE';

//MESHLINK
export const MESHLINK_SUCCESS = 'MESHLINK_SUCCESS';
export const MESHLINK_LOADING = 'MESHLINK_LOADING';
export const MESHLINK_FAILURE = 'MESHLINK_FAILURE';

export const MESHLINK_COURTS_LOADING = "MESHLINK_COURTS_LOADING"
export const MESHLINK_COURTS_SUCCESS = "MESHLINK_COURTS_SUCCESS"
export const MESHLINK_COURTS_FAILURE = "MESHLINK_COURTS_FAILURE"
//SPORTS
export const GET_SPORTS_SUCCESS = 'GET_SPORTS_SUCCESS';
export const GET_SPORTS_LOADING = 'GET_SPORTS_LOADING';
export const GET_SPORTS_FAILURE = 'GET_SPORTS_FAILURE';

//user
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';

export const CHANGE_LOCALE = 'CHANGE_LOCALE'

//REDUX
export const RESET_REDUX_STATE = 'RESET_REDUX_STATE';
