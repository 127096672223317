import { lazy } from 'react';
import {Route} from 'react-router-dom';
const  PageCommunications = lazy(()=> import( '../../pages/Communications/PageCommunications'));

const RoutesCommunications = (
    <>
        {/*COMMUNICATIONS MODULE*/}
        <Route>
            <Route path="/communications" element={<PageCommunications/>}/>
        </Route>

    </>
);

export default RoutesCommunications;
