import {Box, LinearProgress} from '@mui/material';
import { PAGE_HEADER_HEIGHT } from '../../../constants/ui';

function SuspensePageLoadFallback(){
  return (
      <Box sx={{
        width:"100vw",
        height:`calc(100vh - ${PAGE_HEADER_HEIGHT})`,
        position:"relative",
      }}>
        <LinearProgress sx={{
          position: "absolute",
          top:"0",
          left:"0",
          right:"0",
          width:"100%"
        }}/>
      </Box>
  )
}

export default SuspensePageLoadFallback;
