import RoutesSuperAdminCompanies from './RoutesSuperAdminCompanies';
import RoutesSuperAdminClubs from './RoutesSuperAdminClubs';
import RoutesSuperAdminSettings from './RoutesSuperAdminSettings';
import RoutesSuperAdminProfile from './RoutesSuperAdminProfile';
import RoutesSuperAdminCompanyAdmins from './RoutesSuperAdminCompanyAdmins';
import RoutesSuperAdminCustomers from './RoutesSuperAdminCustomers';
import RoutesSuperAdminSuperAdmins from './RoutesSuperAdminSuperAdmins';

const RoutesSuperAdmin = (
    <>
      {RoutesSuperAdminCompanies}
      {RoutesSuperAdminClubs}
      {RoutesSuperAdminCustomers}
      {RoutesSuperAdminSettings}
      {RoutesSuperAdminProfile}
      {RoutesSuperAdminCompanyAdmins}
      {RoutesSuperAdminSuperAdmins}
    </>
);

export default RoutesSuperAdmin;