import {statusError, statusLoading, statusSuccess} from './status';
import {
  CLUBS_SWITCHER_GET_FAILURE,
  CLUBS_SWITCHER_GET_LOADING,
  CLUBS_SWITCHER_GET_SUCCESS,
} from '../constants';
import axios from 'axios';

export const clubsSwitcher_GET = (companyId, cbSuccess, cbFail) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(CLUBS_SWITCHER_GET_LOADING));
      const url = `clubs/switcher/${companyId}`;
      const response = await axios.get(url);
      dispatch(statusSuccess(CLUBS_SWITCHER_GET_SUCCESS, response.data.data));
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
      dispatch(statusError(CLUBS_SWITCHER_GET_FAILURE, e));
    }
  };
};
