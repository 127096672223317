import {useEffect, useState} from 'react';
import {locationsCountriesList_GET} from '../vendor/redux/actions/locations';
import {Outlet} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {Box, LinearProgress} from '@mui/material';


function InitSuperAdmin() {

  const dispatch = useDispatch();

  const [hasFetchedCountriesList, setHasFetchedCountriesList] = useState(false);

  const isLoading =  !hasFetchedCountriesList 


  function fetchCountriesList() {
    dispatch(locationsCountriesList_GET(cbSuccess, cbFail));

    function cbSuccess(res) {
      console.log('locationsCountriesList_GET Success', res);
      setHasFetchedCountriesList(true);
    }

    function cbFail(e) {
      console.log('locationsCountriesList_GET Fail', e);
      setHasFetchedCountriesList(true);
    }
  }

  /*Fetch Countries*/
  useEffect(() => {
    fetchCountriesList();
  }, []);

  if (isLoading) {
    return <Box
        sx={{
          width: '100vw',
          height: '100vh',
          position: 'relative',
        }}
    >
      <LinearProgress sx={{
        position: 'absolute',
        top: 0,
        width: '100%',
        left: 0,
      }}/>

    </Box>;
  }

  if (!isLoading) {
    return <Outlet/>;
  }

}

export default InitSuperAdmin;
