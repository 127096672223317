import {statusError, statusLoading, statusSuccess} from './status';
import {
  CLUBS_GENERIC_LIST_GET_FAILURE,
  CLUBS_GENERIC_LIST_GET_LOADING,
  CLUBS_GENERIC_LIST_GET_SUCCESS,
  CLUBS_INTEGRATION_LIST_GET_FAILURE,
  CLUBS_INTEGRATION_LIST_GET_LOADING,
  CLUBS_INTEGRATION_LIST_GET_SUCCESS,
} from '../constants';
import axios from 'axios';

export const clubsList_GET = (cbSuccess, cbFail) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`clubs/list`);
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
    }
  };
};

export const clubsIntegrationList_GET = (
  clubId,cbSuccess, cbFail) => {
return async (dispatch) => {
  try {
    dispatch(statusLoading(CLUBS_INTEGRATION_LIST_GET_LOADING));
    const response = await axios.get(`/clubs/integrations/${clubId}`);
    dispatch(statusSuccess(CLUBS_INTEGRATION_LIST_GET_SUCCESS, response.data?.data));
    cbSuccess(response);
  } catch (e) {
    cbFail(e);
    dispatch(statusError(CLUBS_INTEGRATION_LIST_GET_FAILURE, e));
  }
};
};
