import {Box} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import LogoPalla from '../../assets/logos/LogoPalla';

function LayoutMainHeaderTopLogo() {

  const navigateTo = useNavigate();

  function handleClick() {
    navigateTo('/');
  }

  return (
      <Box
          onClick={handleClick}
          sx={{
            cursor: 'pointer',
          }}
      >
        <LogoPalla/>
      </Box>
  );
}

export default LayoutMainHeaderTopLogo;