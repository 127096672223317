import {statusError, statusLoading, statusSuccess} from './status';
import {
  ADD_COMPANY_FAILURE,
  ADD_COMPANY_LOADING,
  ADD_COMPANY_SUCCESS,
  CONNECT_MOLLIE_FAILURE,
  CONNECT_MOLLIE_LOADING,
  CONNECT_MOLLIE_SUCCESS,
  EDIT_COMPANY_FAILURE,
  EDIT_COMPANY_LOADING,
  EDIT_COMPANY_SUCCESS,
  GET_COMPANY_FAILURE,
  GET_COMPANY_LOADING,
  GET_COMPANY_SUCCESS,
  GET_DOMAIN_FAILURE,
  GET_DOMAIN_LOADING,
  GET_DOMAIN_SUCCESS,
  SET_COMPANY_DEFAULTS,
} from '../constants';
import axios from 'axios';

export const companyByID_GET = (id, cbSuccess, cbFail) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(GET_COMPANY_LOADING));
      const response = await axios.get(`company/${id}`);
      dispatch(statusSuccess(GET_COMPANY_SUCCESS, response.data));
      cbSuccess(response);
    } catch (e) {
      dispatch(statusError(GET_COMPANY_FAILURE, e));
      cbFail(e);
    }
  };
};

export const connectCompanyWithMollie = (id,body, cbSuccess, cbFail) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(CONNECT_MOLLIE_LOADING));
      const response = await axios.post(`company/generateMollieLink/${id}`,body);
      dispatch(statusSuccess(CONNECT_MOLLIE_SUCCESS, response.data));
      cbSuccess(response);
    } catch (e) {
      dispatch(statusError(CONNECT_MOLLIE_FAILURE, e));
      cbFail(e);
    }
  };
};

export const companyCreate_POST = (body, cbSuccess, cbFail) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(ADD_COMPANY_LOADING));
      const response = await axios.post('company/create', body);
      dispatch(statusSuccess(ADD_COMPANY_SUCCESS, response.data));
      cbSuccess(response);
    } catch (e) {
      dispatch(statusError(ADD_COMPANY_FAILURE, e));
      cbFail(e);
    }
  };
};

export const companyEditByID_PATCH = (companyId, body, cbSuccess, cbFail) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(EDIT_COMPANY_LOADING));
      const response = await axios.patch(`company/edit?companyId=${companyId}`, body);
      dispatch(statusSuccess(EDIT_COMPANY_SUCCESS, response.data));
      cbSuccess(response);
    } catch (e) {
      dispatch(statusError(EDIT_COMPANY_FAILURE, e));
      cbFail(e);
    }
  };
};

export const companyList_GET = (cbSuccess, cbFail) => {
  return async (dispatch) => {
    try {
      const url = `company/list`;
      const response = await axios.get(url);
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
    }
  };
};

export const companyLocalize_GET = (companyId, cbSuccess, cbFail) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/company/localize/${companyId}`);
      dispatch(statusSuccess(SET_COMPANY_DEFAULTS, response.data.data[0]));
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
    }
  };
};

export const domiansList_GET = ( cbSuccess, cbFail) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(GET_DOMAIN_LOADING));
      const response = await axios.get(`domain/list`);
      dispatch(statusSuccess(GET_DOMAIN_SUCCESS, response.data));
      cbSuccess(response);
    } catch (e) {
      dispatch(statusError(GET_DOMAIN_FAILURE, e));
      cbFail(e);
    }
  };
};