import {LayoutMainNavbarClubManager} from './LayoutMainNavbarClubManager';
import {LayoutMainNavbarSuperAdmin} from './LayoutMainNavbarSuperAdmin';

function LayoutMainHeaderBottom({role}) {

  if (role === 'club_manager'){
    return <LayoutMainNavbarClubManager/>;
  }

  if (role === 'super_admin'){
    return <LayoutMainNavbarSuperAdmin/>
  }

}

export default LayoutMainHeaderBottom;
