import {Route} from 'react-router-dom';

const RoutesRestricted = (
    <>
        <Route path={'/restricted'}
               element={<div>Restricted Content</div>}/>
    </>
)

export default RoutesRestricted;
