import useUser from './useUser';

function useIsRole(role) {

  const user = useUser();
  const userRole = user?.userObj?.role;

  return userRole === role

}

export default useIsRole;