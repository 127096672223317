import {Route} from 'react-router-dom';
import { lazy } from 'react';
  const PageSettingsSuperAdmin = lazy(() => import('../../pages/Settings/PageSettingsSuperAdmin'));
  
const RoutesSuperAdminSettings = (
    <>
      <Route
          path={'/settings'}
          element={<PageSettingsSuperAdmin/>}
      />
    </>
)

export default RoutesSuperAdminSettings;