import {statusError, statusLoading, statusSuccess} from './status';
import {
  POLICIES_FAILURE,
  POLICIES_LOADING,
  POLICIES_SUCCESS,
  POLICIES_UPDATE_FAILURE,
  POLICIES_UPDATE_LOADING,
  POLICIES_UPDATE_SUCCESS,
} from '../constants';

import axios from 'axios';

export const clubsPolicyList_GET = (clubId, cbSuccess, cbFail) => {
  return async (dispatch) => {
    try {
      const url = `/clubs/policy/list/${clubId}`;
      dispatch(statusLoading(POLICIES_LOADING));
      const response = await axios.get(url);
      dispatch(statusSuccess(POLICIES_SUCCESS, response.data.data));
      cbSuccess(response);
    } catch (e) {
      cbFail(e)
      dispatch(statusError(POLICIES_FAILURE, e));
    }
  };
};

export const clubsPoliciesList_GET = (clubId, cbSuccess, cbFail) => {
  return async () => {
    try {
      const url = `/clubs/policy/list/${clubId}`;
      const response = await axios.get(url);
      cbSuccess(response);
    } catch (e) {
      cbFail(e)
    }
  };
};

export const clubsPolicyEdit_PATCH = (id, body, cbSuccess, cbFail) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(POLICIES_UPDATE_LOADING));
      const response = await axios.patch(
          `/clubs/policy/edit/${id}`, body);
      dispatch(statusSuccess(POLICIES_UPDATE_SUCCESS, response.data));
      cbSuccess(response);
    } catch (e) {
      console.log(e);
      cbFail(e);
      dispatch(statusError(POLICIES_UPDATE_FAILURE, e));
    }
  };
};
