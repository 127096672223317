import {Route} from 'react-router-dom';
import AccessProtected from '../../access/AccessProtected';
import {
  CAPABILITY_CREATE,
  CAPABILITY_READ,
  CAPABILITY_READ_ALL,
  CAPABILITY_UPDATE,
} from '../../constants/capabilities';
import {MODULE_EXTRAS} from '../../constants/modules';
import { lazy } from 'react';
const  PageExtrasList = lazy(()=> import( '../../pages/Extras/PageExtrasList'));
const  PageExtrasAddEdit = lazy(()=> import( '../../pages/Extras/PageExtrasAddEdit'));
const  PageExtrasDetail = lazy(()=> import( '../../pages/Extras/PageExtrasDetail'));


const RoutesSettingsExtras = (
    <>
      <Route path={`/settings/extras`}>
        {/*READ_ALL EXTRAS*/}
        <Route element={<AccessProtected
            capabilities={CAPABILITY_READ_ALL}
            module={MODULE_EXTRAS}
        />}>
          <Route index element={<PageExtrasList/>}/>
        </Route>
        {/*CREATE EXTRAS*/}
        <Route element={<AccessProtected
            capabilities={CAPABILITY_CREATE}
            module={MODULE_EXTRAS}
        />}>
          <Route path={`add`} element={<PageExtrasAddEdit mode={'add'}/>}/>
        </Route>
        {/*UPDATE EXTRAS*/}
        <Route element={<AccessProtected
            capabilities={CAPABILITY_UPDATE}
            module={MODULE_EXTRAS}
        />}>
          <Route path={`edit`} element={<PageExtrasAddEdit mode={'edit'}/>}/>
        </Route>
        {/*READ EXTRAS*/}
        <Route element={<AccessProtected
            capabilities={CAPABILITY_READ}
            module={MODULE_EXTRAS}
        />}>
          <Route path={`detail/:id`} element={<PageExtrasDetail/>}/>
        </Route>

      </Route>
    </>
);

export default RoutesSettingsExtras;
