import {Route} from 'react-router-dom';
import AccessProtected from '../../access/AccessProtected';
import {
  CAPABILITY_CREATE,
  CAPABILITY_READ_ALL,
} from '../../constants/capabilities';
import {MODULE_PACKAGES} from '../../constants/modules';
import { lazy } from 'react';
const  PagePackagesList = lazy(()=> import( '../../pages/Packages/PagePackagesList'));
const  PagePackageAddEdit = lazy(()=> import( '../../pages/Packages/AddEditPackage'));
const  PackageDetail = lazy(()=> import( '../../pages/Packages/PackageDetail'));


const RoutesFinancials = (
    <>
        {/*READ_ALL sPackagesList*/}
        <Route element={<AccessProtected
            capabilities={CAPABILITY_READ_ALL}
            module={MODULE_PACKAGES} //letter change with MODULE_PACKAGES
        />}>
            <Route
                path={`/packages`}
                element={<PagePackagesList/>}
            />
            <Route path={`/packages/detail/:id/*`} element={<PackageDetail/>}/>
            
        </Route>
        <Route element={<AccessProtected
            capabilities={CAPABILITY_CREATE}
            module={MODULE_PACKAGES} //letter change with MODULE_PACKAGES
        />}>
            <Route
                path={`/packages/add`}
                element={<PagePackageAddEdit mode={'add'}/>}
            />
             <Route
                path={`/packages/edit`}
                element={<PagePackageAddEdit mode={'edit'}/>}
            />
        </Route>
        

    </>
)

export default RoutesFinancials;
