import {Route} from 'react-router-dom';
import AccessProtected from '../../access/AccessProtected';
import {
  CAPABILITY_CREATE,
  CAPABILITY_READ,
  CAPABILITY_READ_ALL,
  CAPABILITY_UPDATE,
} from '../../constants/capabilities';
import {MODULE_CUSTOMERS} from '../../constants/modules';
import { lazy } from 'react';
const  PageCustomersList = lazy(()=> import( '../../pages/Customers/PageCustomersList'));
const  PageCustomersAddEdit = lazy(()=> import( '../../pages/Customers/PageCustomersAddEdit'));
const  PageBookingsDetailRecurringSingle = lazy(()=> import( '../../pages/Bookings/PageBookingsDetailRecurringSingle'));
const  PageBookingsDetailRecurring = lazy(()=> import( '../../pages/Bookings/PageBookingsDetailRecurring'));
const  PageWalletsTransactionDetail = lazy(()=> import( '../../pages/Wallets/PageWalletsTransactionDetail'));
const  PageCustomersProfile = lazy(()=> import( '../../pages/Customers/PageCustomersProfile'));
const  PageBookingsDetailReservation = lazy(()=> import( '../../pages/Bookings/PageBookingsDetailReservation'));

const RoutesCustomers = (
    <>
        <Route path={`/customers`}>

            {/*READ_ALL CUSTOMERS*/}
            <Route element={<AccessProtected
                capabilities={CAPABILITY_READ_ALL}
                module={MODULE_CUSTOMERS}
            />}>
                <Route index element={<PageCustomersList/>}/>
            </Route>

            {/*CREATE CUSTOMERS*/}
            <Route element={<AccessProtected
                capabilities={CAPABILITY_CREATE}
                module={MODULE_CUSTOMERS}
            />}>
                <Route path={`add`}
                       element={<PageCustomersAddEdit mode={'add'}/>}/>
            </Route>

            {/*UPDATE CUSTOMERS*/}
            <Route element={<AccessProtected
                capabilities={CAPABILITY_UPDATE}
                module={MODULE_CUSTOMERS}
            />}>
                <Route path={`:customerId/profile/edit`}
                       element={<PageCustomersAddEdit mode={'edit'}/>}/>
            </Route>

            {/*READ CUSTOMERS*/}
            <Route element={<AccessProtected
                capabilities={CAPABILITY_READ}
                module={MODULE_CUSTOMERS}
            />}>
              <Route path={`:customerId/profile/bookings/reservation/detail`}
                     element={
                       <PageBookingsDetailReservation type={'customer'}/>
                     }
              />
                <Route path={`:customerId/profile/bookings/recurring/detail`}
                       element={
                           <PageBookingsDetailRecurringSingle type={'customer'}/>
                       }
                />
                <Route path=":customerId/profile/bookings/recurring/recurrence-detail"
                       element={
                           <PageBookingsDetailRecurring type={'customer'}/>}/>

                <Route path=":customerId/profile/wallet/detail"
                       element={
                           <PageWalletsTransactionDetail type={'customer'}/>}/>

                <Route path=":customerId/profile/*"
                       element={<PageCustomersProfile role={'club_manager'}/>}/>
            </Route>

        </Route>

    </>
)

export default RoutesCustomers;
